import React from 'react';
import styled from 'styled-components';

import PortableText from '../portableText';

const ReviewMain = ({ author, reviewLink, quote, source }) => {
  return (
    <StyledNewsMain className="container">
      <section
        style={{ maxWidth: '35em', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <PortableText blocks={quote} />

        {reviewLink && reviewLink.linkUrl !== 'none' ? (
          <StyledSourceContainer>
            <p>
              &mdash;{'  '}
              {author ? `${author},` : ''} <a href={reviewLink.linkUrl}>{source}</a>
            </p>
          </StyledSourceContainer>
        ) : (
          <StyledSourceContainer>
            <p>
              &mdash;{'  '}
              {author ? `${author},` : ''} <span>{source}</span>
            </p>
          </StyledSourceContainer>
        )}
      </section>
    </StyledNewsMain>
  );
};

const StyledNewsMain = styled.main`
  margin-top: 4.875rem;
  margin-bottom: 0;

  & > section {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledSourceContainer = styled.div`
  width: 100%;

  & > p {
    margin: 0;
    padding: 0;
    font-style: italic;
  }
`;

export default ReviewMain;
