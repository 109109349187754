import React from 'react';
import styled from 'styled-components';

const PageStripedHeader = ({ children }) => {
  return <StyledStripedHeader>{children}</StyledStripedHeader>;
};

const StyledStripedHeader = styled.header`
  background-color: ${(props) => props.theme.colors.black};
  background: linear-gradient(
      135deg,
      ${(props) => props.theme.colors.primary} 25%,
      ${(props) => props.theme.colors.black} 25%,
      ${(props) => props.theme.colors.black} 75%,
      ${(props) => props.theme.colors.primary} 75%,
      ${(props) => props.theme.colors.primary} 100%
    )
    no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 2rem;
`;

export default PageStripedHeader;
