import React from 'react';
import { Link, graphql } from 'gatsby';
import { Container } from 'react-bootstrap';

import localize from '../components/localize';
import { localizeDate } from '../lib/helpers';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ReviewHeader from '../components/ReviewTemplate/ReviewHeader';
import ReviewMain from '../components/ReviewTemplate/ReviewMain';
import PageFooter from '../components/PageFooter';
import ShareButtons from '../components/ShareButtons';
import RelatedContentBlock from '../components/RelatedContentBlock';

const Review = ({ data, pageContext, path, location }) => {
  const { title, publishedAt, source, author, _rawQuote, reviewLink, reference } = data.review;
  // console.log(reference);
  const localePublishingDate = localizeDate(publishedAt, pageContext.locale, 'long', false);
  return (
    <Layout pageContext={pageContext} path={path} siteTitle={data.site.siteTitle}>
      <SEO title={title} />
      <article>
        <ReviewHeader
          publishedAt={localePublishingDate}
          title={title}
          source={source}
          pathname={location.pathname}
          parentName={data.reviewsPage.title}
        />

        <ReviewMain author={author} quote={_rawQuote} source={source} reviewLink={reviewLink} />

        <PageFooter>
          <Container className="d-flex justify-content-center flex-wrap">
            <ShareButtons url={location.href} title={title} lang={pageContext.locale} />
          </Container>
        </PageFooter>

        <PageFooter>
          {reference && reference.length > 0 ? (
            <RelatedContentBlock
              references={reference}
              lang={pageContext.locale}
              location={location.origin}
            />
          ) : (
            ''
          )}
        </PageFooter>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query ReviewTemplateQuery($id: String!) {
    site: sanitySiteSettings {
      siteTitle
      siteUrl
    }
    reviewsPage: sanityPage(id: { eq: "-2b9d8163-c1f8-5a3b-845d-4de6425d6303" }) {
      title {
        _type
        de
        en
      }
    }
    review: sanityReview(id: { eq: $id }) {
      id
      title {
        _type
        de
        en
      }
      publishedAt
      source
      author
      _rawQuote(resolveReferences: { maxDepth: 5 })
      reviewLink {
        linkUrl
      }
      reference {
        ... on SanityEvent {
          id
          slug {
            current
          }
          title {
            _type
            de
            en
          }
          eventDate
          location
          _rawShortDescription(resolveReferences: { maxDepth: 5 })
        }
        ... on SanityRecording {
          id
          slug {
            current
          }
          title {
            _type
            de
            en
          }
          publishedAt
          imageOnly {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default localize(Review);
