import React from 'react';
import styled from 'styled-components';

import PageStripedHeader from '../PageStripedHeader';
import Breadcrumb from '../Breadcrumb';

const ReviewHeader = ({ publishedAt, title, source, pathname, parentName }) => {
  return (
    <PageStripedHeader>
      <section className="container">
        <Breadcrumb pathname={pathname} parentName={parentName} />
        <StyledNewsMeta>
          <time>{publishedAt}</time>
        </StyledNewsMeta>
        <StyledNewsTitle>{title}</StyledNewsTitle>
        <StyledNewsSource>{source}</StyledNewsSource>
      </section>
    </PageStripedHeader>
  );
};

const StyledNewsMeta = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  padding-top: 1rem;
  margin-bottom: 0;

  & strong {
    font-weight: 500;
  }
`;

const StyledNewsTitle = styled.h1`
  color: ${(props) => props.theme.colors.white};
  font-weight: 500;
`;

const StyledNewsSource = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-size: 0.8125rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
`;

export default ReviewHeader;
